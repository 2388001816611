import { css } from 'lit';

export default css`
  .fil-text-media-block__section:first-child {
    background: unset;
  }

  .fil-text-media-block__video-image,
  .fil-text-media-block__icon-container {
    display: none;
  }

  .fil-text-media-block__video-wrapper {
    height: 100%;
    aspect-ratio: 16/9;
  }

  .fil-text-media-block__section img {
    width: auto;
    max-width: 100%;
  }
`;
