import { callAnalytics } from '_fil/fil-global-frontend/trigger-analytics';
import $ from 'jquery';

class AccordionClass {
  constructor(root) {
    this.$root = $(root);
    this.anchorEnabled = this.$root.attr('anchor-enabled') != undefined;

    if (this.anchorEnabled) {
      this.parseStatesFromLocation();
    }
    this.bindEvents();
  }

  parseStatesFromLocation() {
    const accordionId = window.location.hash.substring(1);
    if (accordionId) {
      const $accordionItem = this.$root.find(`[accordion-id="${accordionId}"]`);
      if ($accordionItem.length) {
        $accordionItem[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
        $accordionItem.prop('expanded', true);
      }
    }
  }

  bindEvents() {
    const accordion_el = this.$root[0];
    accordion_el.addEventListener('click.gds.accordion', evt => {
      if (evt.detail && evt.detail.expanded) {
        const accordionItem = evt.target;
        const question_name = accordionItem.label.trim();

        if (this.anchorEnabled) {
          const accordionId = accordionItem.getAttribute('accordion-id');
          if (accordionId) {
            history.replaceState({}, '', `#${accordionId}`);
          }
        }

        callAnalytics(
          'event',
          'global.interactionEvent',
          'accordion',
          'accordion expand',
          question_name
        );
      }
    });
  }
}

$(() => {
  $('.fil-accordion fil-accordion').each((i, accordion_el) => {
    new AccordionClass(accordion_el);
  });
});
