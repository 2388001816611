import { css } from 'lit';

export default css`
  .fil-table-wrapper table.fil-table tr td a {
    color: var(--fil-color-base-blue-500);
    cursor: pointer;
    line-height: inherit;
    text-decoration: none;
  }
  .fil-table-wrapper table.fil-table tr td a:hover {
    text-decoration: underline;
    color: var(--fil-color-base-grey-800);
  }
  .fil-table-wrapper .fil-table--sort-container {
    align-items: flex-end;
  }

  .fil-table-wrapper table.fil-table tr td fil-button {
    display: inline;
  }

  .fil-table-wrapper table.fil-table tr td .fil-table--cell-icon-label {
    display: flex;
    align-items: center;
  }
  .fil-table-wrapper table.fil-table tr td .fil-table--cell-icon-label fil-icon {
    margin-right: 8px;
    vertical-align: text-top;
    margin-bottom: -6px;
  }
  @media screen and (min-width: 64rem) {
    .fil-table-wrapper table.fil-table tr td .fil-table--cell-icon-label fil-icon {
      order: 2;
      margin-left: 4px;
      margin-right: 0;
    }
  }
  .fil-table-wrapper table.fil-table tr td .fil-table--cell-icon-label span {
    display: inline-block;
  }
`;
