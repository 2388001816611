import { css } from 'lit';

export default css`
  .fil-show-more--secondary label {
    background: linear-gradient(
      transparent,
      var(--fil-color-base-grey-025),
      var(--fil-color-base-grey-025)
    );
  }
`;
