import $ from 'jquery';

$(() => {
  const pageTitle = $('meta[property="og:title"]').attr('content');
  const pageURL = document.location.href;
  let linkedinURL = $('meta[name="linkedin:url"]').attr('content');
  let facebookURL = $('meta[name="facebook:url"]').attr('content');
  let twitterURL = $('meta[name="twitter:url"]').attr('content');
  let mailURL = $('meta[name="mail:url"]').attr('content');
  let lineURL = $('meta[name="line:url"]').attr('content');
  let whatsappURL = $('meta[name="whatsapp:url"]').attr('content');

  $('.fil-social-sharing').each((i, el) => {
    const $socialSharingWrapper = $(el);
    const social_enabled_raw = $socialSharingWrapper
      .find('script[id="social_data_json"]')
      .first()
      .text();
    if (!social_enabled_raw) {
      return;
    }

    let { enable: social_enabled, email: email, twitter: twitter, whatsapp: whatsapp } = JSON.parse(
      social_enabled_raw
    );
    let data = {};
    let url = '';
    if (social_enabled['email'] && email) {
      data = {
        to: email.to,
        cc: email.cc,
        bcc: email.bcc,
        subject: email.subject || pageTitle,
        body: email.body || `${pageURL}${mailURL}`
      };

      url = `mailto:${data.to}?cc=${data.cc.join(',')}&bcc=${data.bcc.join(',')}&subject=${
        data.subject
      }&body=${data.body}`;
      $socialSharingWrapper.find('fil-social.email').attr('href', url);
    }

    if (social_enabled['linkedin']) {
      data = {
        url: `${pageURL}${linkedinURL}`
      };
      url = `https://www.linkedin.com/sharing/share-offsite/?url=${data.url}`;
      $socialSharingWrapper.find('fil-social.linkedin').attr('href', url);
    }

    if (social_enabled['facebook']) {
      data = {
        url: `${pageURL}${facebookURL}`
      };
      url = `https://www.facebook.com/sharer/sharer.php?u=${data.url}`;
      $socialSharingWrapper.find('fil-social.facebook').attr('href', url);
    }

    if (social_enabled['twitter']) {
      data = {
        url: `${pageURL}${twitterURL}`,
        text: pageTitle,
        hashtags: twitter.hashtags.join(',')
      };

      url = `https://twitter.com/share?text=${data.text}&url=${data.url}&hashtags=${data.hashtags}`;
      $socialSharingWrapper.find('fil-social.twitter').attr('href', url);
    }

    if (social_enabled['line']) {
      data = {
        url: `${pageURL}${lineURL}`,
        text: pageTitle
      };
      url = `https://lineit.line.me/share/ui?url=${data.url}&text=${data.text}`;
      $socialSharingWrapper.find('fil-social.line').attr('href', url);
    }

    if (social_enabled['whatsapp']) {
      data = {
        url: `${pageURL}${whatsappURL}`,
        text: pageTitle,
        phone: whatsapp.phone || ''
      };
      url = `https://api.whatsapp.com/send?phone=${data.phone}&text=${data.text}%20${data.url}`;
      $socialSharingWrapper.find('fil-social.whatsapp').attr('href', url);
    }
  });
});
