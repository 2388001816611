import $ from 'jquery';

function dealLayerAnalytics(isin_value) {
  document.dispatchEvent(
    new CustomEvent('analytics', {
      detail: {
        type: 'event',
        action: 'global.interactionEvent',
        event_category: 'deal or invest button',
        event_action: 'Button Click',
        event_content: isin_value
      }
    })
  );
  window._dl = {
    channel: 'Personal Investing',
    page_name: 'fiduk: PI: invest now modal',
    country: 'uk',
    language: 'en',
    site_section: 'invest now',
    spa: true
  };
  document.dispatchEvent(
    new CustomEvent('analytics', {
      detail: {
        type: 'pageview'
      }
    })
  );
}

$(() => {
  $('.fil-table-wrapper fil-table-adr').on('click.gds.button', evt => {
    const paths = evt.composed ? evt.composedPath() : evt.originalEvent.composedPath();
    const butDealer = paths.find(
      node =>
        node && node.tagName && node.tagName.toLowerCase() == 'fil-button' && node.label == 'Deal'
    );

    if (butDealer) {
      let { 'isin-data': isinData, 'access-journey': accessJourney } = butDealer.extraProps;
      dealLayerAnalytics(isinData);

      if (window.openDealLayer && typeof window.openDealLayer == 'function') {
        window.openDealLayer(isinData, accessJourney);
      }
    }
  });
});
