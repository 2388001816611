import { FilCard } from '@fil-global/fil-card/src/index.js';
import customStyles from './fil-card.styles';

export class FilCardExtended extends FilCard {
  static get properties() {
    return {
      bgColor: { type: String }, // blue-500, blue-400, blue-300, teal-500
      ...super.properties
    };
  }

  static get styles() {
    return [...super.styles, customStyles];
  }

  updated(changedProperties) {
    if (changedProperties.has('icon')) {
      if (this.bgColor) {
        const iconElement = this.shadowRoot.querySelector('.fil-card__icon');
        iconElement.classList.add('fil-card__colored');
        iconElement.setAttribute('data-bg-color', this.bgColor);
      }
    }
  }
}
