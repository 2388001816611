import $ from 'jquery';

$(() => {
  $('.fil-messaging').each((_, elem) => {
    const messaging = elem.querySelector('fil-page-level-message');
    if (messaging) {
      const ctaLink = messaging.shadowRoot.querySelector('.fil-page-level-message__link fil-link');
      if (ctaLink) {
        ctaLink.setAttribute('target', '_blank');
      }
    }

    elem.addEventListener('close.gds.page-level-message', event => {
      let isShown = event.detail.value;
      if (!isShown) {
        elem.classList.add('hide');
      }
    });
  });
});
