require('intersection-observer');
import $ from 'jquery';

const wcLazyLoadAttributes = {};
const LAZYLOAD_WC_CLASS = 'lazy-load-wc';

let intersectionListener = new IntersectionObserver(
  entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting === true || entry.intersectionRatio > 0) {
        let element = entry.target;
        const wcName = element.tagName.toLowerCase();
        const attributes = wcLazyLoadAttributes[wcName];

        if (attributes) {
          attributes.forEach(attr => {
            const value = element.getAttribute(`data-${attr}`);
            if (value) {
              element.setAttribute(attr, value);
              element.classList.remove(LAZYLOAD_WC_CLASS);
              element.removeAttribute(`data-${attr}`);
            }
          });
        }
      }
    });
  },
  { rootMargin: '200px 0%' }
);

$(() => {
  let lazyElements = [].slice.call(document.querySelectorAll(`.${LAZYLOAD_WC_CLASS}`));
  lazyElements.forEach(lazyElement => intersectionListener.observe(lazyElement));
});

export function registerLazyLoadAttribute(wcName, attributes) {
  wcLazyLoadAttributes[wcName] = attributes;
}
