import $ from 'jquery';

// TODO:
// the content here is copied from `addons-dev/global-cms-modal/global_cms_modal/core_assets/gds3/js/utils.js`
// copied here due to the incorrect GDS3 migration order,  global-cms-modal is about to release later in the plan.
// we can remove this file after modal is released.
export function modalButtonEvent(modal_id) {
  let modal_title = $(`#${modal_id} fil-modal-heading`).text();

  if (modal_id) {
    let page_name = window._dl.page_name.split(' ').includes('modal')
      ? window._dl.page_name
      : `${window._dl.page_name}: ${modal_title} modal`;

    window._dl = {
      ...window._dl,
      page_name: page_name,
      spa: true,
      message: []
    };

    document.dispatchEvent(
      new CustomEvent('analytics', {
        detail: {
          type: 'pageview'
        }
      })
    );
  }
}
