import URL from 'core-js/web/url';
import $ from 'jquery';
import { FILModalFactory } from './index';

//TODO: need to check the logic
// `$('.login-from-tool a')` doesn't exist in GDS3 as a link will be replaced by fil-button webcomponent.
export function detect_login_from_tools() {
  /**
   *
   * Logic to check if a user is already logged in. if so then open a windown
   *
   */
  $('.buy-funds').on('click', evt => {
    evt.preventDefault();
    let isin = $(evt.target).data('isin');
    window.buyFund(isin);
  });
}

export function bind_modal_triggers() {
  $('fil-button[data-open], a[data-open], [data-modal-opener-wc][data-open]').on('click', evt => {
    const modalID = $(evt.currentTarget).data('open');
    if (modalID) {
      FILModalFactory.updateZIndex(modalID);
      $(`#${modalID} > fil-modal`).prop('active', true);
    }
  });
}

export function modalButtonEvent(modal_id) {
  let modal_title = $(`#${modal_id} fil-modal-heading`).text();

  if (modal_id) {
    let page_name = window._dl.page_name.split(' ').includes('modal')
      ? window._dl.page_name
      : `${window._dl.page_name}: ${modal_title} modal`;

    window._dl = {
      ...window._dl,
      page_name: page_name,
      spa: true,
      message: []
    };

    document.dispatchEvent(
      new CustomEvent('analytics', {
        detail: {
          type: 'pageview'
        }
      })
    );
  }
}

/**
 * Open a login modal deep linking to the buy fund and open account journeys
 *
 * Note: This function *needs* to be available on window as it is called by
 * existing tools rendered in iframes
 *
 * @param {string} isin
 */
window.buyFund = function(isin) {
  accountOpeningDeepLink(isin);

  let baseURL = window.loginCheckURL || window.location.toString();

  // Login check URL is probably configured to point to the API endpoint, so
  // strip xapi. from the domain to retrieve the URL of the frontend
  baseURL = baseURL.replace('xapi.', '');
  const secureRoute = '/secure/transaktionen/kaufen';

  const secureURL = new URL(secureRoute, baseURL);
  secureURL.protocol = 'https';
  secureURL.search = '';
  secureURL.searchParams.set('isin', isin);

  window.openLoginModal(secureURL.toString());
};

/**
 * Set up ISIN deep linking on the account opening anchor in the login from
 * tool modal
 *
 * @param {string} isin
 */
function accountOpeningDeepLink(isin) {
  let anchor = $('.login-from-tool a[href*=account-opening]');
  let href = anchor.attr('href') || '';

  if (href.indexOf('http://') === -1 && href.indexOf('https://') === -1) {
    href = window.location.origin + href;
  }

  if (href) {
    const url = new URL(href);
    url.hash = `/isin:${isin}`;
    anchor.attr('href', url);
  } else {
    // eslint-disable-next-line no-console
    console.warn('No account opening link found in login modal');
  }
}

window.openLoginModal = function(urlLogin) {
  let urlLoginMultipleFunds = getIsinsFromStorage(urlLogin);
  let urlRedirectLogin =
    urlLoginMultipleFunds.indexOf('xapi.') > -1
      ? urlLoginMultipleFunds.replace('xapi.', '')
      : urlLoginMultipleFunds;

  if ('withCredentials' in new XMLHttpRequest()) {
    $.ajax({
      url: `${parent.loginCheckURL}?_=${new Date().getTime()}`,
      type: 'GET',
      dataType: 'json',
      xhrFields: { withCredentials: true },
      success: function(data) {
        sessionStorage.removeItem('isins');
        if (data.accounts) {
          window.location.href = urlRedirectLogin;
        } else {
          window.revealLoginPopup(urlRedirectLogin);
        }
      },
      error: function() {
        window.revealLoginPopup(urlRedirectLogin);
      }
    });
  }
};

window.revealLoginPopup = function(urlLogin) {
  /**
   * Change the link that the login link to point to the secure site
   * including the approriate link.
   */

  const accountOpenLink = buildTheAOLinkURL(urlLogin);

  let accountOpeningLink = parent.$('.login-from-tool a.arrow-right-cta')[0];
  let loginCard = parent.$('.login-from-tool a.arrow-right-cta')[1];

  $(loginCard).attr('href', urlLogin);
  $(accountOpeningLink).attr('href', accountOpenLink);
  //parent.$('.login-from-tool').foundation('open');
  parent.$('.login-from-tool fil-modal').prop('active', true);
};

function getIsinsFromStorage(urlWithParams) {
  let isins = sessionStorage.getItem('isins');
  if (isins) {
    let isinsList = isins.split(',');

    let matches = urlWithParams.match(/[a-z\d]+=[a-z\d]+/gi);
    let count = matches.length;

    let url = '';

    for (let i = 0; i < isinsList.length; i++) {
      url += `&isin_${count + i + 1}=${isinsList[i]}`;
    }

    return `${urlWithParams}${url}`;
  }

  return urlWithParams;
}

function buildTheAOLinkURL(accountOpeningURL) {
  let aoURL = '';
  let url = new URL(accountOpeningURL);
  let domain = url.hostname;
  let protocol = url.protocol;
  let matches = accountOpeningURL.match(/[a-z\d]+=[a-z\d]+/gi);
  let params = url.search;
  if (matches.length === 1) {
    aoURL = `${protocol}//${domain}/account-opening/index-new.html#/isin:${params.split('=')[1]}`;
    return aoURL;
  } else {
    let isins = [];
    let isinListFromParams = params.split('&');
    isinListFromParams.map(isin => {
      isins.push({ isin: isin.split('=')[1] });
    });
    aoURL = `${protocol}//${domain}/account-opening/index-new.html#/isin:${JSON.stringify(isins)}`;
    return encodeURI(aoURL);
  }
}
