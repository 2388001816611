import { modalButtonEvent } from './utils';
import { registerLazyLoadAttribute } from '_fil/fil-global-frontend/lazy-load';
import $ from 'jquery';

$(() => {
  const $footers = $('.fil-cards fil-card-footer');

  $footers.on('click', 'fil-button, .fil-button', function() {
    const modal_id = $(this).attr('data-open');
    modalButtonEvent(modal_id);
  });

  $footers.on('click', function(evt) {
    evt.stopPropagation();
  });
});

// lazy load
registerLazyLoadAttribute('fil-card', ['icon', 'image']);
