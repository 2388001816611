import { html } from 'lit';
import { styleMap } from 'lit/directives/style-map.js';
import { FilTable } from '@fil-global/fil-table/src/index.js';

import customStyles from './fil-table.styles';

export class filTableExtended_ADR extends FilTable {
  static get styles() {
    return [...super.styles, customStyles];
  }

  firstUpdated() {
    super.firstUpdated();
    const sortDropdown = this.shadowRoot.querySelector('.fil-table--sort-container fil-select');
    const sortArrowButton = sortDropdown.nextElementSibling;
    sortDropdown.label = 'Sort by';
    sortArrowButton.styles['height'] = '100%';
    sortDropdown.requestUpdate();
    sortArrowButton.requestUpdate();
  }

  getComponentTemplate(item, row) {
    let output = super.getComponentTemplate(item, row);
    switch (item.type) {
      case 'image':
        output = html`
          <div class="fil-table--cell-image">
            <img src="${item.image}" alt="${item.value}" />
            <div>${item.value}</div>
          </div>
        `;
        break;
      case 'icon-label':
        output = html`
          <div class="fil-table--cell-icon-label" style=${styleMap(item.styles)}>
            <fil-icon size="${item.iconSize}" icon="${item.icon}"></fil-icon>
            <span>${item.label}</span>
          </div>
        `;
        break;
    }
    return output;
  }
}
