import { css } from 'lit';

export default css`
  :host(fil-card) .fil-card__image[style^='background-image'] {
    background-size: cover;
    aspect-ratio: 1.64;
    background-position: left;
  }

  :host(fil-card) .fil-card__colored {
    margin: 0;
    padding: 30px 0;
    color: #fff;
  }

  :host(fil-card) .fil-card__colored[data-bg-color='blue-500'] {
    background-color: var(--fil-color-base-blue-500);
  }

  :host(fil-card) .fil-card__colored[data-bg-color='blue-400'] {
    background-color: var(--fil-color-base-blue-400);
  }

  :host(fil-card) .fil-card__colored[data-bg-color='blue-300'] {
    background-color: var(--fil-color-base-blue-300);
  }

  :host(fil-card) .fil-card__colored[data-bg-color='teal-500'] {
    background-color: var(--fil-color-base-teal-500);
  }
`;
