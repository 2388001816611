import { css } from 'lit';

export default css`
  :host(fil-button) a.fil-button[target='_blank']::after {
    content: '\\e815';
    position: relative;
    font-family: fidelity-icon-font;
    font-size: 0.72222em;
    margin-left: 0.625rem;
  }
  :host(fil-button) a.fil-button--with-icon[target='_blank']::after {
    content: '';
  }
  :host(fil-button) a.fil-button--textlink[target='_blank'] {
    text-decoration: none;
  }
  :host(fil-button) a.fil-button--textlink[target='_blank'] .fil-button--text {
    text-decoration: underline;
  }
  :host(fil-button) a.fil-button--textlink[target='_blank']:hover .fil-button--text {
    text-decoration: none;
  }
`;
