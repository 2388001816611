if (CustomElementRegistry) {
    CustomElementRegistry.prototype.define = (function(oldWebComponentDefineFunc) {
      return function(name, constructor, options = {}) {
        const { unlocked } = options;
        if (this._customElemLockedTags.includes(name) && !unlocked) {
          return false;
        }
  
        if (!this.get(name)) {
          oldWebComponentDefineFunc.apply(this, arguments);
        }
      };
    })(CustomElementRegistry.prototype.define);
  
    //you need to update this if you want to override some tag
    //this temp solution is used to register the overrided webcomponent class to
    //the same tag, as they use `:host(fil-tag-name)` in their style.
    //when you call it with `unlocked` options as below, it can be registered.
    //`customElements.define('fil-tag', TagComponentClass, {unlocked: true});`
    CustomElementRegistry.prototype._customElemLockedTags = [
      // 'fil-author',
      'fil-button',
      'fil-card',
      'fil-show-more',
    ];
  }
  