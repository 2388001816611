import { html } from 'lit';
import { classMap } from 'lit/directives/class-map.js';
import { FilShowMore } from '@fil-global/fil-show-more/src/index.js';
import customStyles from './fil-show-more.styles';

export class FilShowMoreExtended extends FilShowMore {
  static get properties() {
    return {
      appearance: { type: String }, // 'primary' | 'secondary'
      ...super.properties
    };
  }

  static get styles() {
    return [...super.styles, customStyles];
  }

  render() {
    const classes = {
      [`fil-show-more--${this.appearance}`]: this.appearance !== 'primary'
    };
    const super_rendered = super.render();

    return html`
      <div class="fil-show-more-outer-wrapper${classMap(classes)}">${super_rendered}</div>
    `;
  }

  constructor() {
    super();
    this.appearance = 'primary';
  }
}
