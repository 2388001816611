import $ from 'jquery';
import { MediaQuery } from 'foundation-sites/js/foundation.util.mediaQuery';

const TAB_HASH_KEY = 'tab';

class FILTab {
  constructor(root) {
    this.root = root;
    this.$tab = $('fil-tab', root);
    this.bindEvents();
    this.parseStatesFromLocation();
  }

  parseStatesFromLocation() {
    this.searchParams = new URLSearchParams(window.location.hash.substring(1));
    const activeTabId = this.searchParams.get(TAB_HASH_KEY);
    if (activeTabId) {
      this.updateActiveIndex(activeTabId);
    }
  }

  bindEvents() {
    const tab = this.$tab[0];
    this.activeIndex = tab.activeIndex;
    tab.addEventListener('click.gds.tab', () => {
      const activeIndex = tab.activeIndex;
      if (activeIndex !== this.activeIndex) {
        this.updateLocationHash();
        this.activeIndex = activeIndex;
      }
    });
  }

  updateActiveIndex(activeTabId) {
    const tab = this.$tab[0];
    const activeLink = this.$tab.find(`fil-tab-item[tab-id="${activeTabId}"]`)[0];
    if (activeLink) {
      const activeIndex = parseInt(activeLink.getAttribute('data-index'));
      if (tab.activeIndex !== activeIndex) {
        tab.activeIndex = activeIndex;
        tab.updateTabs(activeLink, activeIndex);
      }
    }
  }

  updateLocationHash() {
    const $activeLink = this.$tab.find('fil-tab-item.is-active');
    const anchor = $activeLink.attr('tab-id');
    if (anchor) {
      location.hash = `${TAB_HASH_KEY}=${anchor}`;
    }
  }
}

class FILLinkTab {
  constructor(root) {
    this.root = root;
    this.$tab = $('fil-tab', root);
    this.bindEvents();
    this.initTabItems();
  }

  initTabItems() {
    const $tab = this.$tab;
    const $tabroot = $($tab[0].shadowRoot);
    const container = $tabroot.find('.fil-tabs--wrapper')[0];
    MediaQuery._init();

    $tab.find('fil-tab-item').each((index, item) => {
      const href = item.getAttribute('href');
      const activeIndex = parseInt(item.getAttribute('data-active'));

      if (href) {
        $tabroot.find(`.fil-tabs__title[data-index="${index}"] .fil-tab__link`).attr('title', href);
      }
      if (activeIndex >= 0) {
        $tab.prop('activeIndex', activeIndex);
        //below is to disiplay the active hidden menu, click it and hide the secondary menu.
        const $activePopMenuLink = $tabroot.find(
          `.fil-tabs--secondary .fil-tabs__title[data-index="${activeIndex}"]`
        );
        const $moreMenuRoot = $tabroot.find('.fil-tabs__more--menu');
        const isActiveItemInMainTab =
          $moreMenuRoot.is('.fil-tabs--hidden') || $activePopMenuLink.is('.fil-tabs--hidden');

        if (MediaQuery.is('medium up') && !isActiveItemInMainTab) {
          $activePopMenuLink.trigger('click');
          container.classList.remove('show-secondary');
        }
      }
    });
  }

  bindEvents() {
    const tab = this.$tab[0];
    tab.addEventListener('click.gds.tab', () => {
      const href = this.$tab
        .find('fil-tab-item')
        .eq(tab.activeIndex)
        .attr('href');
      if (href) {
        top.location.href = href;
      }
    });
  }
}

$(function() {
  $('.fil-tabs').each((_, root) => {
    if (root.getAttribute('tab-link')) {
      new FILLinkTab(root);
    } else {
      new FILTab(root);
    }
  });
});
