import { callAnalytics } from '_fil/fil-global-frontend/trigger-analytics';
import $ from 'jquery';

export class FILModal {
  constructor(root) {
    this.$modal = $('fil-modal', root);
    this.modalID = root.id;
    this.init();
    this.bindEvents();
  }

  init() {
    this.adjustChildrenLayout();
    this.checkEnv();
  }

  updateZIndex(zIndex) {
    this.$modal.attr('zIndex', zIndex);
  }

  adjustChildrenLayout() {
    //TODO: adjust children layout to make them display correctly.
    this.$modal.find('.grid-container').addClass('full');
    this.$modal
      .find('.card-container')
      .removeClass('medium-4')
      .addClass('medium-6');
  }

  bindEvents() {
    this.$modal.find('.footer-button--primary').on('click touch', () => {
      this.close();
    });
    this.$modal[0].addEventListener('close.gds.modal', () => {
      const modal_title = this.$modal.find('fil-modal-heading').text();

      callAnalytics('event', 'global.interactionEvent', 'modal or pop-up', 'closed', modal_title);
    });
  }

  checkEnv() {}

  open() {
    this.$modal.prop('active', true);
  }

  close() {
    this.$modal.prop('active', false);
  }
}
