import { callAnalytics } from '_fil/fil-global-frontend/trigger-analytics';
import $ from 'jquery';

const ANIMATION_DURATION = 1000;
const ANIMATION_TOP_OFFSET = 50;

$(() => {
  $('.fil-showhide fil-show-more').on('click', event => {
    const showmore_el = event.target;
    if (!showmore_el.shadowRoot) {
      return;
    }

    const checkbox_el = showmore_el.shadowRoot.querySelector('input[type="checkbox"]');
    const is_checked = checkbox_el.getAttribute('checked');
    if (is_checked) {
      const title_name = $(showmore_el)
        .prev('.title-keyline')
        .text()
        .trim();
      callAnalytics(
        'event',
        'global.interactionEvent',
        'accordion',
        'accordion expand',
        title_name
      );
    } else {
      const $showhide_wrapper = $(showmore_el).closest('.fil-showhide');
      $('html, body').animate(
        { scrollTop: $showhide_wrapper.offset().top - ANIMATION_TOP_OFFSET },
        ANIMATION_DURATION
      );
    }

    return false;
  });
});
