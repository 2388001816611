import URL from 'core-js/web/url';
import { FILModal } from './modal';

export class FILWelcomeModal extends FILModal {
  checkEnv() {
    const url = new URL(location.href);
    if (url.searchParams.get('welcome') == 'true') {
      const modalId = url.searchParams.get('modalId');
      if (!modalId || modalId == this.modalID) {
        this.open();
      }
    }
  }
}
